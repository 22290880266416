// Basis-URL für JSON-Server-Requests

//export const BASE_URL = "http://localhost/mydatabase"

export const BASE_URL = "https://park-app.mike-work.com/mydatabase"


// Zum Starten des Json Server
// npx json-server --watch src/assets/db.json --port 3111

//export const BASE_URL = "http://localhost:3111"